import React from 'react'

import Layout from '../Layout'

import { theme } from '../utils/theme'

const ContactPage = () => {
  return <Layout colorTheme={theme.colors.primary} />
}

export default ContactPage
